import { type GetStaticPropsResult } from 'next'
import dynamic from 'next/dynamic'

import { homePageModuleQuery } from '@data/sanity/queries/page'
import { type SanityHomePage } from '@data/sanity/queries/types/page'
import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import { defaultModuleQueryParameters, getHomePage } from '@lib/sanity/page'
import {
  type BasePageProps,
  getNonNullValues,
  getPagePropsFromContext,
  getPageSanityClient,
} from '@lib/page'
import { type CustomStaticPropsContext } from '@lib/routes'
import { getOrganizationSchema } from '@lib/schema/organization'
import { getVideoObjectSchemas } from '@lib/schema/video'
import { getWebPageSchema, getWebSiteSchema } from '@lib/schema/web'

import Layout from '@components/layout'
import Modules from '@modules/modules'

const PreviewModules = dynamic(() => import('@modules/preview-modules'))

type HomePageProps = BasePageProps & {
  page: SanityHomePage
  site: SanitySiteFragment
}

const HomePage = ({ draftMode, locale, page, site }: HomePageProps) => {
  const organizationSchema = getOrganizationSchema(
    site.schemaSettings?.organization,
  )
  const webSiteSchema = getWebSiteSchema(site)
  const webPageSchema = getWebPageSchema(page, site)
  const videoObjectSchemas = getVideoObjectSchemas(page)
  const schemas = [
    organizationSchema,
    webSiteSchema,
    webPageSchema,
    ...videoObjectSchemas,
  ]

  return (
    <Layout page={page} site={site} schemas={schemas} draftMode={draftMode}>
      {!draftMode && <Modules modules={page.modules} />}
      {draftMode && (
        <PreviewModules
          modules={page.modules}
          query={homePageModuleQuery}
          params={{
            ...defaultModuleQueryParameters,
            locale,
          }}
        />
      )}
    </Layout>
  )
}

export async function getStaticProps(
  context: CustomStaticPropsContext,
): Promise<GetStaticPropsResult<HomePageProps>> {
  const sanityClient = getPageSanityClient(!!context.draftMode)
  const homePage = await getHomePage(sanityClient, context.locale)

  if (!homePage.page) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      ...getPagePropsFromContext(context),
      page: getNonNullValues(homePage.page),
      site: getNonNullValues(homePage.site),
    },
    revalidate: 60,
  }
}

export default HomePage
